<template>
    <section id="create-stmt">
        <div class="container">
            <div class="block-title">СОЗДАТЬ ЗАЯВКУ</div>
            <div class="form-block">
                <div class="row form-head">
                    <div class="col-sm-12 head-top-line">
                        <a :class="{ active: generalForm }" href="javascript://" @click="generalForm = true">Подать заявку во все банки</a>
                        <span class="px-2">|</span>
                        <a :class="{ active: !generalForm }" href="javascript://" @click="generalForm = false">Подать заявку в банк</a>
                    </div>
                    <div v-if="generalForm" class="col-sm-12 head-bottom-line">
                        <span>Зарегистрируйтесь на сервисе <span class="text-green-dark">Bankon24</span> и отслеживайте сделки в каждом банке в режиме online</span>
                    </div>
                    <div v-else id="select-banks" class="col-sm-12">
                        <a class="bank-item btn btn-sm btn-secondary" :class="{ active: selectedBankKey === bank.key }" @click="selectedBankKey = bank.key" href="javascript://" v-for="bank in banks" :key="bank.key">{{ bank.name }}</a>
                    </div>
                    <div class="col">
                        <hr>
                    </div>
                </div>
                <form v-if="generalForm" id="create-stmt-form" @submit.prevent="onSubmit">
                    <div class="row">
                        <div class="col-md-6">
                            <b-input-group>
                                <input class="input--disabled" type="text" v-mask="['############']" v-model="form.inn" placeholder="ИНН организации" required>
                            </b-input-group>
                            <b-input-group>
                                <input class="input--disabled" type="text" v-model="form.fio" placeholder="ФИО контактного лица/руководителя" required>
                            </b-input-group>
                            <b-input-group>
                                <input class="input--disabled" type="email" v-model="form.email" placeholder="Почта контактного лица/руководителя" required>
                            </b-input-group>
                            <b-input-group>
                                <input class="input--disabled" type="text" v-mask="['+7 (###) ###-##-##']" v-model="form.phone" placeholder="Телефон контактного лица/руководителя" required>
                            </b-input-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-row>
                                <b-col cols="9">
                                    <input class="border-success" v-model.lazy="form.sum" v-money="money" placeholder="0,00" required>
                                </b-col>
                                <b-col cols="3" class="pl-3">
                                    <input class="w-auto" type="text" placeholder="Руб" size="2" disabled>
                                </b-col>
                            </b-form-row>
                            <b-form-row>
                                <b-col cols="9">
                                    <b-row>
                                        <b-col>
                                            <date-picker v-model="form.dateFrom" :valueType="dateFormat"></date-picker>
                                        </b-col>
                                        <b-col class="d-flex align-items-center p-0" cols="auto">
                                            <span>-</span>
                                        </b-col>
                                        <b-col>
                                            <date-picker v-model="form.dateTo" :valueType="dateFormat"></date-picker>
                                        </b-col>
                                    </b-row>
                                </b-col>    
                                <b-col cols="3" class="pl-3">
                                    <input type="text" placeholder="Дней" :value="dateDiff + ' дней'" disabled>
                                </b-col>
                            </b-form-row>
                            <b-form-row>
                                <b-col cols="9">
                                    <b-row>
                                        <b-col>
                                            <input type="text" v-model="form.captcha" placeholder="Код с изображения" required>
                                        </b-col>
                                        <b-col class="d-flex align-items-center px-1" cols="auto"></b-col>
                                        <b-col id="captcha">
                                            <Captcha ref="captcha" class="input-style" @onLoad="captchaOnLoad"/>
                                        </b-col>
                                    </b-row>
                                </b-col>    
                                <b-col cols="3" class="pl-3">
                                    <button class="w-100 h-100" @click="refreshCaptch" type="button" :disabled="captchaLoading">
                                        <mdicon class="text-green-light" :class="{'mdi-spin': captchaLoading}" name="refresh" size="17"/>
                                        <div>Обновить картинку</div>
                                    </button>
                                </b-col>
                            </b-form-row>
                            <b-form-row>
                                <b-col class="py-2">
                                    <b-form-checkbox
                                        id="checkbox-1"
                                        v-model="form.agreementNotifications"
                                        name="checkbox-1"
                                        value="accepted"
                                        unchecked-value="not_accepted"
                                        required
                                        >
                                            Даю Согласие на получение информационных сообщений
                                    </b-form-checkbox>
                                </b-col>
                            </b-form-row>
                        </div>
                    </div>
                    <div v-if="formError" class="row mt-4">
                        <div class="col-sm-12 small text-center text-danger">{{ formError }}</div>
                    </div>
                    <div class="block-footer row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-warning btn-submit text-white" type="submit">Оформить кредит</button>
                        </div>
                    </div>
                </form>
                <div v-else id="stmt-to-bank">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="row bank-logo-block">
                                <div class="col-sm-12 text-center">
                                    <img :src="selectedBank.logo" :alt="selectedBank.name">
                                </div>
                            </div>
                            <div class="row bank-terms-block">
                                <div class="col-sm-4 text-center">
                                    <div class="info-title">Сумма кредита</div>
                                    <div class="info-text">{{ selectedBank.summ }}</div>
                                </div>
                                <div class="col-sm-4 text-center">
                                    <div class="info-title">Срок кредита</div>
                                    <div class="info-text">{{ selectedBank.term }}</div>
                                </div>
                                <div class="col-sm-4 text-center">
                                    <div class="info-title">Ставка</div>
                                    <div class="info-text">{{ selectedBank.rate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-warning btn-submit text-white" @click="stmtToBank">Оформить кредит</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import banks from '@/banks';

export default {
    components: {

    },
    data() {
        return {
            generalForm: true,
            selectedBankKey: 'alfa',

            captchaLoading: false,
            token: null,

            formError: null,
            form: {
                status: null,
                sum: 0.00
            },

            dateFormat: 'DD.MM.YYYY',
            money: {
                decimal: ',',
                thousands: ' ',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false
            },

            banks: banks.filter(item => !item.hidden)
        }
    },
    computed: {
        selectedBank() {
            return this.banks.find(bank => bank.key === this.selectedBankKey);
        },

        dateDiff() {
            const diffDays = Math.abs(this.$moment(this.form.dateFrom, this.dateFormat).diff(this.$moment(this.form.dateTo, this.dateFormat), 'days'));
            return diffDays ? diffDays : 0;
        }
    },
    methods: {
        captchaOnLoad(captchaData) {
            this.token = captchaData.token;
        },

        // Обновить капчу
        refreshCaptch() {
            this.captchaLoading = true;
            this.$refs.captcha.refresh().then(() => {
                this.captchaLoading = false;
            });
        },

        // Очистить форму
        resetForm() {
            this.form = {};
        },

        // Заявка в банк
        stmtToBank() {
            window.open(this.selectedBank.link, '_blank').focus();
        },

        // Отправка заявки
        onSubmit() {
            let formDataMap = {
                sum: {
                    name: 'Сумма',
                    valueFormat(value) {
                        return value ? value + ' руб.' : value;
                    }
                },
                inn: {
                    name: 'ИНН организации'
                },
                fio: {
                    name: 'ФИО контактного лица/руководителя'
                },
                email: {
                    name: 'Почта контактного лица/руководителя'
                },
                phone: {
                    name: 'Телефон контактного лица/руководителя'
                }
            };

            let formData = this.$prepareFormData(formDataMap, this.form);

            this.formError = null;
            this.$axios.post('/api/v1/mail/send', {
                request: this.token,
                captcha: this.form.captcha,
                data: formData
            })
            .then((response) => {
                if (response.data.success && typeof response.data.data !== 'undefined') {
                    this.resetForm();
                    this.$swal('Заявка отправлена!', '', 'success');
                } else {
                    throw response.data.error;
                }
            })
            .catch((error) => {
                this.formError = null;
                console.log(error);

                if (typeof error.detail !== 'undefined') {
                    this.formError = error.detail;
                }

                this.$swal('Ошибка отправки!', this.formError ? this.formError : 'Повторите попытку позже', 'warning');
            })
            .finally(() => {
                this.$refs.captcha.refresh();
            });
        }
    }
}
</script>

<style lang="scss">
#create-stmt {
    @include down(1050) {
        .container {
            padding: 0 50px;
        }
    }

    @include down(980) {
        .container {
            .form-block {
                #create-stmt-form {
                    display: flex;
                    flex-flow: column;

                    & > div {
                        flex: 1;
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .container {
        max-width: 970px;
    }

    .block-title {
        font-weight: 500;
        font-size: 41.6023px;
        line-height: 47px;
        text-transform: uppercase;
        color: $blueDark;
        margin-top: 81px;
        margin-bottom: 70px;
        text-align: center;
    }

    .form-block {
        background: $whtie;
        padding: 47px 70px 38px 70px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-bottom: 135px;

        .form-head {
            text-align: center;
            padding-bottom: 17px;
            margin-bottom: 22px;

            .head-top-line {
                font-size: 15px;
                line-height: 17px;
                text-transform: uppercase;
                margin-bottom: 20px;

                a {
                    color: $greyDark;

                    &:hover {
                        text-decoration: none;
                    }

                    &.active {
                        color: $green-dark;
                    }
                }
            }

            .head-bottom-line {
                font-weight: 500;
                font-size: 15px;
                line-height: 17px;
            }
            
            hr {
                width: 100%;
                height: 3px;
                background: $green-light;
                border-radius: 5px;
                margin-top: 24px;
            }

            #select-banks {
                .bank-item {
                    margin-right: 26px;
                    padding: 5px 10px;
                    font-size: 15px;
                    line-height: 17px;
                    background: $greyLight;
                    border: 1px solid rgba(185, 187, 190, 0.3);

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &.active {
                        background: $green-light;
                    }
                }
            }
        }

        // Форма создания заявки
        #create-stmt-form {
            #captcha {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    padding: 0;
                    height: 42px;
                }
            }

            @include down(980) {
                & > .row:first-of-type {
                    flex-flow: column;
                    margin: 0;

                    & > div {
                        width: 100%;
                        max-width: 100%;
                        
                        &:first-of-type {
                            margin-bottom: 25px;
                        }
                    }
                }
            }

            .block-footer {
                margin-top: 39px;
            }
        }

        // Отправка заявки в банк
        #stmt-to-bank {
            .bank-logo-block {
                margin-bottom: 51px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 65px;

                img {
                    max-height: 100%;
                }
            }

            .bank-terms-block {
                margin-bottom: 70px;

                .info-title {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 23px;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                }

                .info-text {
                    font-weight: 500;
                    font-size: 30px;
                    line-height: 34px;
                    text-transform: uppercase;
                    color: $green-dark;
                }
            }
        }
    }
}
</style>