import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import mdiVue from 'mdi-vue';
import * as mdijs from '@mdi/js';
import VModal from 'vue-js-modal';
import swal from 'sweetalert';
import Axios from 'axios';
import VueTheMask from 'vue-the-mask';
import money from 'v-money';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import Captcha from '@/components/Captcha';

import 'vue2-datepicker/index.css';
import './assets/scss/style.scss';

Vue.component('Captcha', Captcha);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VModal);
Vue.use(VueTheMask);
Vue.use(DatePicker);
Vue.use(money, { precision: 4 });
Vue.use(mdiVue, {
    icons: mdijs
});

Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
Axios.defaults.headers.common = {
    'Content-Type': 'application/json',
    'Authorization': process.env.VUE_APP_API_TOKEN
};

// Подготовить данные для отправки формы
Vue.prototype.$prepareFormData = (dataMap, formData, params = {}) => {
    params = Object.assign({
        excludeEmpty: true
    }, params);

    let result = {}

    Object.keys(formData).forEach((key) => {
        if (typeof dataMap[key] !== 'undefined') {
            let field = dataMap[key];

            if (params.excludeEmpty && !formData[key].length) {
                return;
            }

            result[key] = {
                name: field.name,
                value: typeof field.valueFormat === 'function' ? field.valueFormat(formData[key]) : formData[key]
            }
        }
    });

    return result;
}

Vue.use({
    install (Vue) {
        Vue.swal = swal;
        Vue.prototype.$swal = swal;
        Vue.prototype.$axios = Axios;
        Vue.prototype.$moment = moment;
        Vue.prototype.$scrollTo = (selector) => {
            document.querySelector(selector).scrollIntoView();
        }
    }
})

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');
