<template>
    <section id="consultation">
        <div class="container">
            <div class="row">
                <div class="col-sm"></div>
                <div class="col-sm-5 right-block">
                    <div class="block-title">ОСТАЛИСЬ ВОПРОСЫ?</div>
                    <form @submit.prevent="onSubmit">
                        <b-input-group>
                            <input type="text" v-model="form.name" placeholder="Имя *" required>
                        </b-input-group>
                        <b-input-group>
                            <the-mask :mask="['+7 (###) ###-##-##']" v-model="form.phone" placeholder="Телефон *" required/>
                        </b-input-group>
                        <b-input-group>
                            <input type="email" v-model="form.email" placeholder="Email">
                        </b-input-group>
                        <b-input-group>
                            <b-col class="p-0">
                                <input type="text" v-model="form.captcha" placeholder="Код с изображения *" required>
                            </b-col>
                            <b-col class="d-flex align-items-center px-1" cols="auto"></b-col>
                            <b-col id="captcha">
                                <Captcha ref="captcha" class="input-style" @onLoad="captchaOnLoad"/>
                            </b-col>
                        </b-input-group>
                        <div class="row form-conditions-block">
                            <div class="col-sm-12 pl-0">* Поля со&nbsp;звездочкой обязательны для&nbsp;заполнения</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <button class="btn btn-warning btn-submit text-white" type="submit">Получить <br>консультацию</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            token: null,

            form: {
                captcha: '',
                name: '',
                phone: '',
                email: ''
            }
        }
    },
    methods: {
        captchaOnLoad(captchaData) {
            this.token = captchaData.token;
        },

        // Очистить форму
        resetForm() {
            this.form = {};
        },

        onSubmit() {
            let formDataMap = {
                name: {
                    name: 'Имя'
                },
                phone: {
                    name: 'Телефон'
                },
                email: {
                    name: 'Email'
                }
            };

            let formData = this.$prepareFormData(formDataMap, this.form);

            this.$axios.post('/api/v1/mail/send', {
                request: this.token,
                captcha: this.form.captcha,
                data: formData
            })
            .then((response) => {
                if (response.data.success && typeof response.data.data !== 'undefined') {
                    this.resetForm();
                    this.$swal('Заявка отправлена!', '', 'success');
                } else {
                    throw response.data.error;
                }
            })
            .catch((error) => {
                this.formError = null;
                console.log(error);

                if (typeof error.detail !== 'undefined') {
                    this.formError = error.detail;
                }

                this.$swal('Ошибка отправки!', this.formError ? this.formError : 'Повторите попытку позже', 'warning');
            })
            .finally(() => {
                this.$refs.captcha.refresh();
            });
        }
    }
}
</script>

<style lang="scss">
#consultation {
    background: url('../../assets/images/consultation-bg.png'), $greyLighter;
    background-position-x: -125px;
    background-size: cover;

    @include down(1545) {
        background-position-x: -310px;
    }

    @include down(1300) {
        background-position-x: -672px;

        .container {
            width: 60%;
        }

        .row {
            flex-flow: column;

            .right-block {
                flex: 1;
                max-width: 100%;
                text-align: center;
            }
        }
    }

    @include down(870) {
        background-image: none;
    }

    .block-title {
        font-weight: 500;
        font-size: 41.6023px;
        line-height: 47px;
        text-transform: uppercase;
        color: $blueDark;
        margin-top: 177px;
        margin-bottom: 50px;
    }

    .right-block {
        form {
            margin-bottom: 126px;

            .form-conditions-block {
                margin: 29px 0 33px 0;
                font-size: 12px;
                line-height: 14px;
                color: $blueDark;
            }

            #captcha {
                padding: 0;
                height: 42px;
            }

            button[type="submit"] {
                width: 300px;
                height: 70px;
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
}
</style>