<template>
    <section id="main">
        <div class="container">
            <Header/>
            <div class="main-title">
                <div>Экспресс-кредитование <br>для бизнеса</div>
            </div>
            <div class="main-sub">
                <div>Получите простой и быстрый доступ <br>к кредитованию по самым выгодным <br>условиям</div>
            </div>
            <button class="btn btn-lg btn-success" @click="$scrollTo('#create-stmt')">Оформить кредит</button>
        </div>
    </section>
</template>

<script>
import Header from '../../components/Header';

export default {
    components: {
        Header
    }
}
</script>

<style lang="scss">
#main {
    background: url('../../assets/images/main-bg.png');
    background-size: cover;
    padding-bottom: 104px;

    @include down(1220) {
        .container {
            width: 100%;
            max-width: 1100px;
            padding: 0px 50px !important;
        }
    }

    @include down(780) {
        background: $bnBg !important;
    }

    @include down(570) {
        .container {
            .main-title {
                margin-top: 10px !important;
                font-size: 40px !important;
                line-height: 50px !important;
            }
        }
    }

    .container {
        .main-title {
            font-family: PlumbBoldC;
            font-weight: bold;
            font-size: 48.8708px;
            line-height: 71px;
            text-transform: uppercase;
            color: $whtie;
            margin-top: 48px;
        }

        .main-sub {
            font-style: normal;
            font-weight: 500;
            font-size: 23.435px;
            line-height: 27px;
            color: $whtie;
            margin-top: 30px;
        }

        button {
            margin-top: 43px;
        }
    }
}
</style>