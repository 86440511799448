<template>
    <div class="captcha-container">
        <img v-if="src" :src="src">
    </div>
</template>

<script>
export default {
    data() {
        return {
            src: null,
            token: null,
            loading: false
        }
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.loading = true;
            return this.$axios.get('/api/v1/captcha')
                .then((response) => {
                    if (typeof response.data.data !== 'undefined') {
                        let data = response.data.data;
                        this.src = 'data:image/' + data.ext + ';base64,' + data.image.content;
                        this.token = data.request;
                    }
                })
                .catch(({error}) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('onLoad', this);
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.captcha-container {
    padding: 0 !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 100%;
    }
}
</style>